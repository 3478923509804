import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Theme from '.././components/Theme';
import { isMobile } from "react-device-detect";
import Home from '.././images/Home.png'
import Book from '.././images/Book.png'
import Email from '.././images/Email.png'

function Layout(props) {

    function menuButtonClick(e)
    {
        var pathname = window.location.pathname;
        if (pathname=="/") {
            props.RemovePulse();
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function GetClassName(element) {
        switch (element) {
            case 'MenuButton':
                return ["", "AlternateText"][props.CURRENT_THEME];
                break;
            case 'MenuButtonImage':
                return ["BlueFilter", "AlternateFilter"][props.CURRENT_THEME];
                break;
            case 'NavBarContainerMobile':
                return ['', 'AlternateNavBarMobile'][props.CURRENT_THEME];
                break;
        }
    }

    function GetSelectedItem(item, comparison) {
        if (item == comparison)
            return 'Selected ';
        return '';
    }

    var pathname = useLocation().pathname;
    var theme = null;
    var selectedItem = 'home';
    if (pathname.indexOf('/Blog') == -1) {
        theme = <Theme CURRENT_THEME={props.CURRENT_THEME} ApplyTheme={props.ApplyTheme} />;

        if (pathname.indexOf('/Contact') != -1) {
            selectedItem = 'contact';
        }
    }
    else {
        selectedItem = 'blog';
    }

    return (
        <>
            <div className="CentralContainer">
                
                <div className="NavBarContainer">
                    {theme}
                    <a className="LogoMenuButton">
                        <img className="Logo"></img>
                    </a>

                    <div className="Menu">
                        <div className="NavItem">
                            <Link id="Home" to="/" className={"MenuButton " + GetSelectedItem(selectedItem, 'home') + GetClassName("MenuButton")} onClick={menuButtonClick}>
                                Home
                            </Link>
                        </div>
                        <div className="NavItem">
                            <Link id="Blog" to="/Blog" className={"MenuButton " + GetSelectedItem(selectedItem, 'blog') + GetClassName("MenuButton")} onClick={menuButtonClick}>
                                Blog
                            </Link>
                        </div>
                        <div className="NavItem">
                            <Link id="Contact" to="/Contact" className={"MenuButton " + GetSelectedItem(selectedItem, 'contact') + GetClassName("MenuButton")} onClick={menuButtonClick}>
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"NavBarContainerMobile " + GetClassName("NavBarContainerMobile")}>
                <div className="MenuMobile">
                    <div className="NavItemMobile">
                        <Link id="Blog" to="/Blog" onClick={menuButtonClick}>
                            <div className="MenuButtonImageContainer"><img key={0} style={{ display: 'inline-block' }} className={"MenuButtonMobile BookAlign MenuButtonImageLarge " + GetSelectedItem(selectedItem, 'blog') + GetClassName("MenuButtonImage")} src={Book}></img></div>
                        </Link>
                    </div>
                    <div className="NavItemMobile">
                        <Link id="Home" to="/">
                            <div onClick={menuButtonClick} className="MenuButtonImageContainer"><img key={1} style={{ display: 'inline-block' }} className={"MenuButtonMobile HomeAlign MenuButtonImage " + GetSelectedItem(selectedItem, 'home') + GetClassName("MenuButtonImage")} src={Home}></img></div>
                        </Link>
                    </div>
                    <div className="NavItemMobile">
                        <Link id="Contact" to="/Contact" onClick={menuButtonClick}>
                            <div className="MenuButtonImageContainer"><img key={2} style={{ display: 'inline-block' }} className={"MenuButtonMobile MailAlign  MenuButtonImageLarge " + GetSelectedItem(selectedItem, 'contact') + GetClassName("MenuButtonImage")} src={Email}></img></div>
                        </Link>
                    </div>
                </div>
            </div>

            <Outlet />
        </>
    )
};

export default Layout;