import React from 'react';
import './Blog.css';
import BlogEntry from './BlogEntry';
import { useLocation } from 'react-router-dom'

function BlogEntryContainer() {
    const location = useLocation()

    return (
        <BlogEntry/>
    );
}

export default BlogEntryContainer;