function Footer(props) {

    function footerClick(e)
    {
        document.getElementById(e.target.name).click();
    }

    function GetClassName(element) {
        switch (element) {
            case 'FooterContainer':
                return ["", "FooterAlternate"][props.CURRENT_THEME];
                break;
            case 'FooterTitle':
                return ["", "FooterTitleAlternate"][props.CURRENT_THEME];
                break;
            case 'FooterSubTitle':
                return ["", "AlternateText"][props.CURRENT_THEME];
                break;
        }
    }

    return (
        <div className={"FooterContainer " + GetClassName("FooterContainer")}>
            <div className="CentralContainer">
                <div className="SubtitleContainer">
                    <div className="Column">
                        <p className={"FooterTitle " + GetClassName("FooterTitle")}>BE</p>
                        <p className="FooterEmail">besoftware.contact@gmail.com</p>
                    </div>
                    <div className="ColumnSeparator"></div>
                    <div className="Column">
                        <p className={"FooterSubTitle " + GetClassName("FooterSubTitle")}>Navigation</p>
                        <div className="RowSeparator"></div>
                        <a name="Home" className="FooterText FooterLink" onClick={footerClick}>Home</a>
                        <a name="Blog" className="FooterText FooterLink" onClick={footerClick}>Blog</a>
                        <a name="Contact" className="FooterText FooterLink" onClick={footerClick}>Contact</a>
                    </div>
                    <div className="Column">
                        <p className={"FooterSubTitle " + GetClassName("FooterSubTitle")}>Services</p>
                        <div className="RowSeparator"></div>
                        <p className="FooterText">Web Development</p>
                        <p className="FooterText">App Development</p>
                        <p className="FooterText">Maintenance</p>
                        <p className="FooterText">Hosting</p>
                        
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    )
};

export default Footer;