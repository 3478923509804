import React from 'react';
import './Blog.css';
import { Row, Col, Container } from 'react-bootstrap'

class BlogEntry extends React.Component
{
    constructor(props) {
        super(props)

        this.state =
        {
            titleEntry: [],
            mainEntry: []
        }
    }

    async componentWillMount() {
        
        this.initBlogEntry();
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    async initBlogEntry()
    {
        var url = window.location.href;
        var entryName = url.split("/").pop().replace(new RegExp('-', 'g'), ' ');;

        var data = await fetch('https://www.besoftware.me/GetBlogEntry/?name=' + entryName, {
            method: 'GET'
        })
        var values = await data.json()

        var title = values['title']
        var info = values['info']
        var content = values['content']

        var titleEntry = [];
        var mainEntry = [];

        titleEntry.push(<p className="BlogEntryTitle  AlternateOrange">{title}</p>)
        titleEntry.push(<p className="BlogHeading2">{info}</p>)

        for (var i = 0; i < content.length; i++)
        {
            var currentContent = content[i];
            var currentContentType = currentContent.slice(0, currentContent.indexOf(":"));
            var currentContentValue = currentContent.slice(currentContent.indexOf(":")+1, currentContent.length);

            switch (currentContentType)
            {
                case "sum":
                    titleEntry.push(<p className="BlogEntrySummary">{currentContentValue}</p>)
                    break;

                case "text":
                    mainEntry.push(<Row><Col><p dangerouslySetInnerHTML={{ __html: currentContentValue }}/></Col></Row>)
                    break;

                case "sub":
                    mainEntry.push(<Row><Col><p className="AlternateOrange BlogEntrySubtitle">{currentContentValue}</p></Col></Row>)
                    break;

                case "img":
                    mainEntry.push(<Row><Col className="EntryImageCol"><img className="BlogEntryImage" src={"https://www.besoftware.me/static/Blog/Images/" + currentContentValue}></img><p className="BlogEntryImageCaption"><i>{content[i + 1]}</i></p></Col></Row>)
                    i++;
                    break;
            }
        }

        this.setState({
            titleEntry: titleEntry,
            mainEntry: mainEntry
        });
    }

    render()
    {
        return (
            <div>
                <div className="ContactHeader BlogHeader"></div>
                <div className="CentralContainer BlogContainer BlogContainerMobile">
                    <div className="BlogPage">

                        <div className="EntryTitleContainer">{this.state.titleEntry}</div>{this.state.mainEntry}
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogEntry;