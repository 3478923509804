import React from 'react';
import '../App.css';
import '../css/Button.scss'
import swoosh from '../images/landing-swoosh.svg'
import alternateSwoosh from '../images/alternate-swoosh.png'
import lightSwoosh from '../images/light-swoosh.png'
import lightSwooshAlternate from '../images/light-swoosh-alternate.png'
import apple from '../images/apple.png'
import codeIcon from '../images/CodingIcon.png'
import clientIcon from '../images/ClientIcon.png'
import finalIcon from '../images/FinalIcon.png'
import themeIcon from '../images/ThemeIcon.png'
import codeIconAlternate from '../images/CodingIconAlternate.png'
import clientIconAlternate from '../images/ClientIconAlternate.png'
import finalIconAlternate from '../images/FinalIconAlternate.png'
import dad from '../images/dad.jpg'
import BobbyEdin from '../images/BobbyEdin.jpg'
import linkedInIcon from '../images/linkedin.png'
import linkedInIconAlternate from '../images/linkedinAlternate.png'
import { Row, Col, Container } from 'react-bootstrap'

function Home(props) {
    function resizeInformationCard(e) {
        var element = e.target;
        if (element.className.indexOf('InformationContainer') == -1)
            element = element.closest('.InformationContainer');
        element.classList.add('InformationLarger');

        var centralInformation = element.closest('.CentralInformation');

        var cards = centralInformation.getElementsByClassName('InformationContainer');

        for (var i = 0; i < cards.length; i++) {
            if (cards[i] != element)
                cards[i].classList.add('InformationSmaller');
        }
    }

    function restoreInformationCard(e) {
        var element = e.target;
        var centralInformation = element.closest('.CentralInformation');
        var cards = centralInformation.getElementsByClassName('InformationContainer');

        for (var i = 0; i < cards.length; i++) {
            cards[i].classList.remove('InformationSmaller');
            cards[i].classList.remove('InformationLarger');
        }
    }

    function clickContact()
    {
        document.getElementById('Contact').click();
    }

    function GetClassName(element)
    {
        switch (element)
        {
            case 'GetInTouchButton':
                return ["Button", "ButtonAlternate"][props.CURRENT_THEME];
                break;

            case 'Swoosh':
                return ["Swoosh", "SwooshAlternate"][props.CURRENT_THEME];
                break;
            case 'LightSwoosh':
                return ["LightSwoosh", "LightSwoosh"][props.CURRENT_THEME];
                break;
            case 'Header':
                return ["", "DarkGrey"][props.CURRENT_THEME];
                break;
            case 'Text':
                return ["TextMobile", "TextMobile"][props.CURRENT_THEME];
                break;
            case 'Subheading':
                return ["", "AlternateGrey"][props.CURRENT_THEME];
                break;
            case 'Bobby':
                return ["", "AlternateBacking"][props.CURRENT_THEME];
                break;
            case 'Information':
                return ["", "InformationIconAlternate"][props.CURRENT_THEME];
                break;
            case 'PicOfMe':
                return ["", "PictureOfMeAlternate"][props.CURRENT_THEME];
                break;
            case 'HomeHeaderMobile':
                return ["", "HomeHeaderMobileAlternate"][props.CURRENT_THEME];
                break;
        }
    }

    function ThemeClick()
    {
        props.ApplyTheme((props.CURRENT_THEME + 1) % 2);
    }

    function GetSource(element)
    {
        switch (element) {
            case 'Swoosh':
                return [swoosh, alternateSwoosh][props.CURRENT_THEME];
                break;
            case 'LightSwoosh':
                return [lightSwoosh, lightSwooshAlternate][props.CURRENT_THEME];
                break;
            case 'Handshake':
                return [clientIcon, clientIconAlternate][props.CURRENT_THEME];
                break;
            case 'Code':
                return [codeIcon, codeIconAlternate][props.CURRENT_THEME];
                break;
            case 'Iterate':
                return [finalIcon, finalIconAlternate][props.CURRENT_THEME];
                break;
            case 'LinkedIn':
                return [linkedInIcon, linkedInIconAlternate][props.CURRENT_THEME];
                break;
            case 'Piccy':
                return [dad, BobbyEdin][props.CURRENT_THEME];
                break;
        }
    }

    return (
        <div>
            <img className={GetClassName('Swoosh')} src={GetSource('Swoosh')}></img>
            <img className={GetClassName('LightSwoosh')} src={GetSource('LightSwoosh')}></img>
            <img className="Configurator" src={apple}></img>
            <div className={"HomeHeaderMobile " + GetClassName('HomeHeaderMobile')}></div>

            <div className="CentralContainer">
                
                <div className="HomeHeaderContainer">
                    
                    <div className={"MobileTitleContainer " + (props.PULSE ? " pulse" : "")} onClick={ThemeClick}>
                        <p className="MobileTitle ">BE</p>
                    </div>
                    <p className="Title">BE Software</p>
                    <p className="SubTitleMicro ">web development</p>
                    <p className="SubTitle ">fulfill your vision</p>
                    <button id="GetInTouchButton" className={'TouchButtonMobile ' + GetClassName('GetInTouchButton')} onClick={clickContact}>Get in touch</button>
                    
                </div>

                <div className="AimContainer">
                    <div className="SubtitleContainer"><p className="DarkHeader LG"></p><p className={"BlueHeader LG " + GetClassName("Header")}>-WHAT I DO-</p></div>
                    <p className={GetClassName("Text")}>I develop websites and web applications for small to medium sized businesses.</p>
                    <p className={GetClassName("Text")}>With years of professional experience building a wide range of different products, whatever your vision might be, I can bring it to life.</p>
                </div>

                <div className="InformationCardsContainer">
                    <Row className="CentralInformation">
                        <Col className="InformationContainer">
                            <img className={"InformationIcon " + GetClassName('Information')} src={GetSource('Handshake')}  ></img>
                            <div className="SubtitleContainer">
                                <p className={"BlueHeader OrangeRed " + GetClassName("Header")}>You Know&nbsp;</p> <p className={"DarkHeader " + GetClassName("Subheading")}>Best</p>
                            </div>
                            <p className={GetClassName("Text")}>My goal is to work closely with you to create the best possible products for your business.</p>
                        </Col>
                        <Col className="InformationContainer">
                            <img className={"InformationIcon " + GetClassName('Information')} src={GetSource('Code')}  ></img>
                            <div className="SubtitleContainer">
                                <p className={"BlueHeader OrangeRed " + GetClassName("Header")}>Test Driven&nbsp;</p> <p className={"DarkHeader " + GetClassName("Subheading")}>Development</p>
                            </div>
                            <p className={GetClassName("Text")}>I write detailed automated and manual tests as I develop, ensuring finished results are robust and reliable.</p>
                        </Col>
                        <Col className="InformationContainer">
                            <img className={"InformationIcon " + GetClassName('Information')} src={GetSource('Iterate')}  ></img>
                            <div className="SubtitleContainer">
                                <p className={"BlueHeader " + GetClassName("Header")}>An Iterative&nbsp;</p> <p className={"DarkHeader " + GetClassName("Subheading")}>Process</p>
                            </div>
                            <p className={GetClassName("Text")}>I believe in an iterative, transparent process where you can use, assess and rework the product throughout the developement lifecycle.</p>
                        </Col>
                    </Row>
                </div>

                <div className="WhoContainer">
                    
                    <div className={"OuterImage " + GetClassName("Bobby")}><img src={GetSource("Piccy")} className={"PictureOfMe " + GetClassName("PicOfMe")}></img></div>
                    <div className="SubtitleContainer"><a href="https://www.linkedin.com/in/bobby-e-632a239a/" target="_blank" rel="noopener noreferrer"><img src={GetSource('LinkedIn')} className={"SocialIcon "}></img></a></div>
                    <div className="SubtitleContainer"><p className={"MobileAboutMe BlueHeader LG " + GetClassName("Header")}>A LITTLE ABOUT ME</p><p className="DarkHeader LG"></p></div>
                    <p className={GetClassName("Text")}>Hi, I'm Bobby! <br/> I'm a software developer in Milton Keynes. Originally from Cambridge, I grew up with a fascination for creating with technology.
                        When I was a child I made silly videos with my friends, then as a teenager I did freelance video editing, and now I'm a software developer. I aspire to be an approachable, kind, helpful person and aim to reflect that in my work.</p>
                    
                </div>
            </div>
        </div>
    );
}

export default Home;
