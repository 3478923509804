function Theme(props) {

    function menuButtonHover(add) {
        var elements = document.getElementsByClassName("ThemeOptionContainer");

        for (var i = 0; i < elements.length; i++) {
            if (add)
                elements[i].classList.add("ThemeOptionContainerHover");
            else
                elements[i].classList.remove("ThemeOptionContainerHover");
		}
    }

    function ChangeTheme(theme) {
        props.ApplyTheme(theme);
    }

    function GetClassName(element) {
        switch (element) {
            case 'MenuButton':
                return ["", "AlternateText"][props.CURRENT_THEME];
                break;
        }
    }

    return (
        <div className="ThemeContainer" onMouseEnter={() => menuButtonHover(true)} onMouseLeave={() => menuButtonHover(false)}>
            <button id="Theme" className={"MenuButton ThemeButton " + GetClassName("MenuButton")}>
                Theme
            </button>
            <div id="ThemeOptionsContainer" className="ThemeOptionsContainer">
                <div className="ThemeOptionContainer">
                    <div class="ThemeCircle ThemeCircleDefault"></div>
                    <button className="ThemeOption" onClick={() => ChangeTheme(0)}>Default</button>
                </div>
                <div className="ThemeOptionContainer">
                    <div class="ThemeCircle ThemeCircleAlternate"></div>
                    <button className="ThemeOption" onClick={() => ChangeTheme(1)}>Alternate</button>
                </div>
            </div>
        </div>
    )
};

export default Theme;