import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import LoadingGif from '../images/LoadingGif.gif'
import '.././App.css';

function Contact(props) {
    var sent = false;

    function isEmptyOrSpaces(str) {
        return str === null || str.match(/^ *$/) !== null;
    }

    async function SendForm(event)
    {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }

        var data = new FormData();

        var name = document.getElementById('Name').value;
        var email = document.getElementById('Email').value;
        var message = document.getElementById('Message').value;

        data.append("name", JSON.stringify(name))
        data.append("number", JSON.stringify(document.getElementById('PhoneNumber').value))
        data.append("email", JSON.stringify(email))
        data.append("company", JSON.stringify(document.getElementById('Company').value))
        data.append("message", JSON.stringify(message))

        document.getElementById("FormContainer").style.display = "none";
        document.getElementById("LoadingContainer").style.display = "block";

        updateSending();

        var data = await fetch('https://www.besoftware.me/PostContact/', {
            method: 'POST',
            body: data
        })

        var values = await data.json()

        var result = values['result']

        document.getElementById("SuccessContainer").style.display = "block";
        document.getElementById("LoadingContainer").style.display = "none";

        sent = true;
    }

    function updateSending()
    {
        var text = document.getElementById('Sending').textContent;

        if (text == "SENDING...")
        {
            text = "SENDING";
        }
        else
        {
            text += ".";
        }

        document.getElementById('Sending').textContent = text;

        if (!sent)
        {
            setTimeout(function () {
                updateSending()
            }, 500)
        }
        else
        {
            sent = false;
        }
    }

    function GoBack()
    {
        document.getElementById("FormContainer").style.display = "block";
        document.getElementById("SuccessContainer").style.display = "none";
    }

    function GetClassName(element) {
        switch (element) {
            case 'ContactHeader':
                return ["", " ContactHeaderAlternate HomeHeaderMobileAlternate "][props.CURRENT_THEME];
                break;
            case 'ClientHeader':
                return ["", "AlternateText"][props.CURRENT_THEME];
                break;

            case 'Rolling':
                return ["Blue", "AlternateOrange"][props.CURRENT_THEME];
                break;

            case 'Submit':
                var x = ["SubmitButton", "SubmitButtonAlternate"][props.CURRENT_THEME];
                return x;
                break;

            case 'Success':
                return ["Blue", ""][props.CURRENT_THEME];
                break;
            case 'ContactHeaderContainer':
                return ["", " ContactHeaderContainerAlternate  HomeHeaderMobileAlternate HomeHeaderMobileAlternateContact "][props.CURRENT_THEME];
                break;
        }
    }

    return (
        <div>
            <div className={"HomeHeaderMobile ContactHeader ContactHeaderMobile" + GetClassName('ContactHeader')}></div>

            <div className={"ContactHeaderContainer " + GetClassName('ContactHeaderContainer')}>
                <p className={"SubTitle ContactTitle " + GetClassName('ClientHeader')}>BECOME A CLIENT</p>
                <p className="SubTitle ContactSubtitle">Get in touch today and let us know about your next big project:</p>
                <p className="SubTitle Email ">-besoftware.contact@gmail.com-</p>
            </div>
            <div className="CentralContainer">
                <div className={"ContactHeaderContainerDesktop "}>
                    <p className={"SubTitle ContactTitle " + GetClassName('ClientHeader')}>BECOME A CLIENT</p>
                    <p className="SubTitle ContactSubtitle">Get in touch today and let us know about your next big project:</p>
                    <p className="SubTitle ContactSubtitle Email ">besoftware.contact@gmail.com</p>
                </div>
                <div id="FormContainer" className="FormContainer" style={{display:'block'}}>
                    <div className="SubtitleContainer ContactTitleContainer">
                        <p className="FormTitle BallRollingMobile ">GET THE BALL &nbsp;</p><p className={"FormTitle BallRollingMobile " + GetClassName("Rolling")}>ROLLING</p>
                    </div>
                    
                    <Form id="ContactForm" onSubmit={SendForm}>
                        <Form.Group>
                            <Form.Control required  className="ControlText" type="text" id="Name"
                                placeholder="Name" />
                        </Form.Group>
                        <Form.Group className="FormSpacing">
                            <Form.Control id="Company" className="ControlText" type="text"
                                placeholder="Company" />
                        </Form.Group>
                        <Form.Group className="FormSpacing">
                            <Form.Control required  id="Email" className="ControlText" type="email"
                                placeholder="Email" />
                        </Form.Group>
                        <Form.Group className="FormSpacing">
                            <Form.Control id="PhoneNumber" className="ControlText" type="number"
                                placeholder="Phone Number" />
                        </Form.Group>
                        <Form.Group className="FormSpacing">
                            <Form.Control required  id="Message" className="ControlText" as="textarea" rows="15"
                                placeholder="Enquiry" />
                        </Form.Group>
                        <div className="SubmitButtonContainer">
                            <Button type="submit" className={"FormSpacing BlueBackground " + GetClassName("Submit") + " ControlText"} variant="primary" >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>

                <div id="LoadingContainer" className="LoadingContainer" style={{ display: 'none' }}>
                    <img className="LoadingGif" src={LoadingGif}></img>
                    <p id="Sending" className={"Sending " + GetClassName("Success")}>SENDING</p>
                </div>

                <div id="SuccessContainer" className="LoadingContainer SuccessContainer" style={{ display: 'none' }}>
                    <p className={"ContactSuccess " + GetClassName("Success")}>SUCCESS!</p>
                    <p className="FormTitle">Thank you for your message.</p>
                    <p className="FormTitle">I will get back to you soon.</p>
                    <Button className={"FormSpacing BlueBackground " + GetClassName("Submit") + " ControlText BackButton"} variant="primary" onClick={GoBack} >
                        BACK
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Contact;
