import React from 'react';
import './Blog.css';
import ConfiguratorLandscape from './../images/ConfiguratorLandscape.png'
import Selenium from './../images/Selenium.png'
import Configurator2 from './../images/Configurator2.png'
import ReactImg from './../images/react.png'
import Share from './../images/Shares.png'
import View from './../images/Views.png'
import Like from './../images/Like.png'
import { Row, Col, Container } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Search from './../images/search.svg';
import { Outlet, Link } from "react-router-dom";

class Blog extends React.Component {
    constructor(props) {
        super(props)

        this.state =
        {
            BlogEntries: [],
            Filter: ""
        }

        this.optionsClick = this.optionsClick.bind(this)
        this.search = this.search.bind(this)
        this.waitForFade = this.waitForFade.bind(this)
    }

    async componentWillMount() {

        this.initBlogEntries();
    }

    componentDidMount()
    {
        document.getElementById("BlogEntriesContainer").style.opacity = 1;
    }

    async initBlogEntries()
    {
        var data = await fetch('https://www.besoftware.me/GetAllEntries/', {
            method: 'GET'
        })
        var values = await data.json()
        var blogValues = values['entries']

        var blogEntries = []
        for (var i = 0; i < blogValues.length; i++)
        {
            var currentValue = blogValues[i];
            blogEntries.push({
                Row: currentValue['Row'],
                Bias: currentValue['Bias'],
                ViewCount: currentValue['ViewCount'],
                LikeCount: currentValue['LikeCount'],
                ShareCount: currentValue['ShareCount'],
                Name: currentValue['Name'],
                Subtitle: currentValue['Subtitle'],
                Date: currentValue['Date'],
                Thumbnail: currentValue['Thumbnail']
            });
        }

        this.setState({
            BlogEntries: blogEntries
        });
    }

    optionsClick(e) {
        var element = e.target;
        var menu = element.closest('.OptionsCol');
        var options = menu.getElementsByClassName('BlogBarLink');

        for (var i = 0; i < options.length; i++) {
            options[i].classList.remove('BlogBarLinkSelected');
        }

        element.classList.add('BlogBarLinkSelected');
        this.orderBlogEntry(element.getAttribute("name"));
    }

    search(e)
    {
        var value = e.target.value.toLowerCase();

        this.setState({ Filter: value });
    }

    orderBlogEntry(order)
    {
        var blogEntrys = this.state.BlogEntries;
        switch (order)
        {
            case 'Newest':
                blogEntrys.sort((a, b) => new Date(a.Date.split('/').reverse().join('-')) - new Date(b.Date.split('/').reverse().join('-')));
                break;
            case 'Popular':
                blogEntrys.sort((a, b) => b.ViewCount - a.ViewCount);
                break;
            case 'Featured':
                blogEntrys.sort((a, b) => b.Bias - a.Bias );
                break;
        }
        this.state.BlogEntries = blogEntrys;

        this.fadeOut();
    }

    getBlogEntries(blogEntries)
    {
        var result = [];
        var row = [];
        var oddNumber = blogEntries.length % 2 == 1;

        for (var i = 0; i < blogEntries.length; i++)
        {
            this.addEntry(blogEntries[i], row, i == 0, (i % 2 == 1 && i == blogEntries.length - 1));

            if (i % 2 == 0 || i == 0 || i == blogEntries.length - 1)
            {
                result.push(<Row className={"BlogRow" + ((i % 2 == 1 && i == blogEntries.length - 1) ? " BlogRowCenter" : "")}>{row}</Row>);
                row = [];
            }
        }

        return result;
    }

    addEntry(blogEntry, row, first, lessSpace)
    {
        var name = blogEntry.Name.replace(new RegExp(' ', 'g'), '-');

        row.push(<Col className="BlogCol">
            <div className={"ImageContainer" + (first ? " TitleImage" : lessSpace ? " SmallerImage" : "")}><div className="Banner">our product</div><Link name={"Link_" + name} to={"/Blog/Entry/" + name} state={{ row: 0 }}><img className="BlogImg" src={"https://www.besoftware.me/static/Blog/Images/" + blogEntry.Thumbnail}></img></Link></div>
            <p name={name} className="BlogSubTitle BlogDate" onClick={this.blogContainerClicked}>{blogEntry.Date}</p>
            <div name={name}  className="Row Center CenterMobile " onClick={this.blogContainerClicked}>{this.buildHighlightedText(blogEntry.Name, this.state.Filter, "BlogTitle")}</div>
            <div name={name}  className="Row Center CenterMobile " onClick={this.blogContainerClicked}>{this.buildHighlightedText(blogEntry.Subtitle, this.state.Filter, "BlogSubTitle")}</div>
        </Col>)
    }

    buildHighlightedText(value, filter, cssClass)
    {
        var textRow = [];

        var theWorld = [];
        var currentWords = '';
        var currentBadWord = '';
        for (var i = 0; i < value.length; i++)
        {
            currentWords += value[i];

            if (filter.toLowerCase().substring(0, currentWords.length) != currentWords.toLowerCase()) {
                currentBadWord += currentWords;
                currentWords = '';
            }
            else if (currentWords.length == filter.length)
            {
                if (currentBadWord.length > 0)
                {
                    theWorld.push({ value: currentBadWord, highlight: false })
                    currentBadWord = '';
                }

                theWorld.push({ value: currentWords, highlight: true })
                currentWords = '';
            }
        }

        if (currentBadWord.length > 0) {
            theWorld.push({ value: currentBadWord, highlight: false })
        }

        for (var i = 0; i < theWorld.length; i++)
        {
            if (theWorld[i].highlight) {
                var npsb = null;
                if (i != 0 && theWorld[i-1].value[theWorld[i-1].value.length - 1] == " ") {
                    npsb = <p className={cssClass}>&nbsp;</p>;
                }

                textRow.push(<div className="Row">{npsb}<p className={cssClass + " BlogHighlighted"}>{theWorld[i].value}</p></div>)
            }
            else
            {
                var npsb = null;
                if (i != 0 && theWorld[i].value[0] == " ") {
                    npsb = <p className={cssClass}>&nbsp;</p>;
                }
                textRow.push(<div className="Row">{npsb}<p className={cssClass}>{theWorld[i].value}</p></div>)
            }
        }

        return textRow;
    }

    fadeOut()
    {
        document.getElementById("BlogEntriesContainer").style.opacity = 0;
        this.waitForFade();
    }

    fadeIn()
    {
        document.getElementById("BlogEntriesContainer").style.opacity = 1;
    }

    waitForFade() {
        if (window.getComputedStyle(document.getElementById("BlogEntriesContainer")).getPropertyValue("opacity") > 0) {
            setTimeout(this.waitForFade, 200);
        }
        else
        {
            var filter = this.state.Filter;
            this.setState({ Filter: filter}, this.fadeIn);
        }
    }

    blogContainerClicked(e) {
        var name = e.currentTarget.getAttribute('name');
        document.getElementsByName("Link_" + name)[0].click();
    }

    render() {
        var filteredEntries = this.state.BlogEntries;
        if (this.state.Filter.length > 0) {
            var filteredEntries = this.state.BlogEntries.filter(entry => entry.Name.toLowerCase().indexOf(this.state.Filter) != -1 || entry.Subtitle.toLowerCase().indexOf(this.state.Filter) != -1)
        }

        return (
            <div>
                <div className="ContactHeader BlogHeader"></div>
                <div className="CentralContainer BlogContainer BlogContainerMobile">
                    <div className="BlogPage">
                        <p className="BlogHeading2 BlogHeading2Mob">LATEST FROM</p>
                        <p className="BlogHeading">be software</p>
                        <Row className="BlogNavBar">

                            <Col className="OptionsCol">
                                <a className="BlogBarLink BlogBarLinkSelected" name="Featured" onClick={this.optionsClick}>featured</a>
                                <a className="BlogBarLink" name="Newest" onClick={this.optionsClick}>newest</a>
                                <a className="BlogBarLink" name="Popular" onClick={this.optionsClick}>popular</a>
                            </Col>
                            <Col className="SearchCol">
                                <div className="Row">
                                    <InputGroup className="SearchContainer">
                                        <FormControl id="Search" onBlur={this.search} className="SearchControl"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>

                                    <img className="SearchImage" src={Search}></img>
                                </div>
                            </Col>
                        </Row>

                        <Row className="BlogNavBar SearchNavMobile">
                            <Col className="SearchColMobile">
                                <div className="Row">
                                    <InputGroup className="SearchContainer">
                                        <FormControl id="Search" onBlur={this.search} className="SearchControl"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>

                                    <img className="SearchImage" src={Search}></img>
                                </div>
                            </Col>
                        </Row>
                        <div className="BlogUnderline"></div>

                        <div id="BlogEntriesContainer" style={{ opacity: 0 }} className="FadeBlog">
                            {this.getBlogEntries(filteredEntries)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Blog;