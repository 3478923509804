import React from 'react';
import './App.css';
import './css/Button.scss'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Layout from "./pages/Layout";
import Footer from "./pages/Footer";
import BlogEntryContainer from './pages/BlogEntryContainer';

class App extends React.Component {
    constructor(props) {
        super(props)

        this.state =
        {
            CURRENT_THEME: 0,
            PULSE: true
        }

        this.ApplyTheme = this.ApplyTheme.bind(this)
        this.RemovePulse = this.RemovePulse.bind(this)
    }

    ApplyTheme(theme)
    {
        if (theme != this.state.CURRENT_THEME) {
            this.setState({CURRENT_THEME: theme });
        }
    }

    RemovePulse()
    {
        this.setState({PULSE: false });
    }

    render() {
        const DEFAULT_THEME = 0;
        const ALTERNATE_THEME = 1;

        return (
            <div className="Home">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout RemovePulse={this.RemovePulse} ApplyTheme={this.ApplyTheme} CURRENT_THEME={this.state.CURRENT_THEME} />}>
                            <Route index element={<Home CURRENT_THEME={this.state.CURRENT_THEME} ApplyTheme={this.ApplyTheme} PULSE={this.state.PULSE} />} />
                            <Route path="Blog" element={<Blog />} />
                            <Route path="Contact" element={<Contact CURRENT_THEME={this.state.CURRENT_THEME} />} />
                            <Route path="/Blog/Entry/*" element={<BlogEntryContainer />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
                <Footer CURRENT_THEME={this.state.CURRENT_THEME} />
            </div>
        );
    }
}

export default App;
